import React from "react"
import { navigate } from "gatsby"
import { Flex, Box, Button, Text, chakra } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"

const styles = {
  root: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    bg: "#F5F5F7",
  },

  flex: {
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
  },
  image: {
    mt: { base: "24px", md: "24px", lg: "0px" },
    minWidth: "160px",
    minHeight: "160px",
    width: "160px",
    height: "160px",
  },
  card: {
    bg: "white",
    maxWidth: { base: "100%", md: "100%", lg: "1112px" },
    border: "1px solid #DCE0E5",
    borderRadius: "8px",
    mt: "32px",
    mb: "32px",
    mx: "24px",
    px: "24px",
    py: "32px",
  },
  "quote-box": {
    my: { base: "24px", md: "24px", lg: "0px" },
    ml: { base: "0px", md: "0px", lg: "32px" },
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "788px" },
  },
  quote: {
    color: "#041424",
    fontWeight: "bold",
    fontSize: "72px",
    fontFamily: "Inter !important",

    textAlign: { base: "left", md: "left", lg: "center" },

    letterSpacing: "-1.44px",
  },

  "bottom-flex": {
    flexDirection: "column",
    alignItems: { base: "flex-start", md: "flex-start", lg: "center" },

    px: "24px",
    pb: "32px",
  },
  "text-box": {
    width: { base: "100%", md: "100%", lg: "515px" },
    mb: { base: "16px", md: "16px", lg: "24px" },
    textAlign: { base: "left", md: "left", lg: "center" },
  },
  text: {
    textAlign: "left",
    fontSize: "18px",
    lineHeight: "28px",
    color: "#041424",
  },
  bold: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#041424",
    lineHeight: "24px",
    mt: "16px",
  },

  button: {
    mt: { base: "16px", md: "16px", lg: "0px" },
    padding: "8px 16px",
    bg: "#129459",
    height: "56px",
    width: { base: "100%", md: "100%", lg: "248px" },
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}
const navigateToContactUs = () => navigate("/contact-us/")
const SectionQuote = () => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.card}>
        <Flex sx={styles.flex}>
          <Box sx={styles.image}>
            <StaticImage
              src="../../images/vbhc/jon_warner.png"
              alt="Photo of Dr. Jon Warner of MGH Othopedics"
              placeholder="blurred"
              style={{ borderRadius: "50%" }}
            />
          </Box>
          <Flex sx={styles["quote-box"]}>
            <Text sx={styles.text}>
              <chakra.span sx={styles.quote}>"</chakra.span>
              Participation in the Value-Based Healthcare Research Group is
              highly rewarding. We work in partnership on timely topics and the
              dedicated support group at Avant-garde Health facilitates the
              logistics allowing the surgeon members to focus on the quality and
              relevancy of published research. This collaboration helps me
              fulfill my research objectives and obligations with optimized
              results and minimax overhead.”
              <Text sx={styles.bold}>
                Dr. Jon “JP” Warner
                <Text></Text>
                Chief MGH Orthopedics Shoulder Service and Chief Quality and
                Safety Officer
                <Text></Text>
                MGH Orthopedics
              </Text>
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex sx={styles["bottom-flex"]}>
        <Box sx={styles["text-box"]}>
          Interested in joining the group? We welcome surgeons who want to join
          our journey of measuring and improving healthcare quality and value.
        </Box>
        <Button sx={styles.button} onClick={navigateToContactUs}>
          Contact Us to Learn More
        </Button>
      </Flex>
    </Flex>
  )
}

export default SectionQuote
