import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Text, Heading, Box, Button, Link } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    height: "100%",
    width: "100%",
    mt: "32px",
    mb: { base: "32px", md: "32px", lg: "48px" },
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "inner-box": {
    margin: "0 auto",
    width: "100%",
    px: { base: "24px", md: "24px", lg: "50px" },
    height: { base: "100%", md: "100%", lg: "588px" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "796px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    mr: { base: "0px", md: "0px", lg: "124px" },
  },
  "heading-one": {
    mt: { base: "24px", md: "24px", lg: "0px" },
    mb: "32px",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#041424",
    textAlign: "left",
  },
  "heading-two": {
    mb: "12px",
    color: "#041424",
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
  },
  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: "left",
  },
  link: {
    color: "#3399FF",
    _hover: {
      color: "#3399FF",
    },
  },
  whitespace: {
    pb: "24px",
  },

  image: {
    height: { base: "auto", md: "auto", lg: "588px" },
    minWidth: { base: "100%", md: "100%", lg: "544px" },
    textAlign: { base: "center", md: "center", lg: "right" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    mt: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: "56px",
    width: { base: "100%", md: "100%", lg: "248px" },
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us/")

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["icon-box"]}>
        <ChevronDownIcon sx={styles.icon} />
      </Flex>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles["heading-one"]}>
          Value-Based Healthcare Research Group
        </Heading>

        <Text sx={styles.text}>
          The Value-Based Healthcare Research Group produces 10 high quality
          research manuscripts for publication each year, in addition to
          abstracts for conference presentations, all with a unifying theme of
          “VALUE.” We welcome all who are interested in joining our group.
          <Text sx={styles.whitespace}></Text>
          The group is currently focused on shoulder surgery in a collaboration
          between Avant-garde Health and the{" "}
          <Link
            sx={styles.link}
            href="https://www.codman-shoulder-society.com/"
            isExternal
          >
            Codman Shoulder Society
          </Link>
          . The Codman Shoulder Society is “a group committed to recording the
          ‘end result’ for every patient, determining best practices and
          optimizing outcomes in shoulder surgery, just as Dr. Codman
          envisioned” more than a century ago.
        </Text>
        <Text sx={styles.whitespace}></Text>
        <Heading sx={styles["heading-two"]}>Our Vision</Heading>
        <Text sx={styles.text}>
          Publish high impact articles that advance our understanding of the
          cost, quality, and value of care, as well as disparities in treatment,
          and apply these insights to improve care.
        </Text>
        <Button sx={styles.button} onClick={navigateToContactUs}>
          Contact Us to Learn More
        </Button>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/vbhc/vbhc-hero.png"
          alt="Illustration of two healthcare professionals looking beakers and test tubes."
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionHero
