import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Box, Heading, Link } from "@chakra-ui/react"
import ResearchHighlight from "../research-highlight"

const styles = {
  root: {
    width: "100%",
    height: "100%",
  },
  "inner-box": {
    margin: "0 auto",

    mb: { base: "32px", md: "32px", lg: "32px" },
    px: "24px",
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "flex-start", md: "flex-start", lg: "flex-start" },
    maxWidth: { base: "100%", md: "100%", lg: "1112px" },
    justifyContent: "space-between",
  },
  "right-box": {
    flexDirection: "column",
    height: "432px",
    maxWidth: { base: "100%", md: "100%", lg: "544px" },
    mt: { base: "24px", md: "24px", lg: "0px" },
  },
  heading: {
    color: "#000000DE",
    fontWeight: "bold",
    margin: "0 auto",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    textAlign: { base: "left", md: "center", lg: "center" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    mt: { base: "24px", md: "42px", lg: "32px" },
    mb: { base: "24px", md: "24px", lg: "32px" },
    ml: { base: "24px", md: "0px", lg: "0px" },
  },

  image: {
    minWidth: { base: "100%", md: "100%", lg: "543px" },
    height: { base: "auto", md: "auto", lg: "432px" },
    mr: { base: "0px", md: "0px", lg: "25px" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },

  link: {
    fontSize: "16px",
    color: "#3399FF",
    lineHeight: "24px",
    mt: "16px",
    textDecoration: "underline",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  ResearchHighlight: {
    root: {
      "&:nth-child(2)": {
        mt: { base: "24px", md: "24px", lg: "16px" },
      },
      "&:nth-child(3)": {
        mt: { base: "24px", md: "24px", lg: "16px" },
      },
    },
  },
}

const SectionPublishedResearch = ({ publications }) => (
  <Box sx={styles.root}>
    <Heading sx={styles.heading}>Published Research</Heading>
    <Flex sx={styles["inner-box"]}>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/vbhc/vbhc-research.png"
          alt="Illustration of a person holding a large magnifying glass in front of a background and foreground of beakers and gears"
          placeholder="blurred"
        />
      </Box>

      <Flex sx={styles["right-box"]}>
        {publications?.map(p => (
          <ResearchHighlight
            key={p.title}
            text={p.title}
            link={p.link.link}
            styles={styles.ResearchHighlight}
          />
        ))}

        <Link as={GatsbyLink} to={"/publications/"} sx={styles.link}>
          All Published Research
        </Link>
      </Flex>
    </Flex>
  </Box>
)

SectionPublishedResearch.propTypes = {
  publications: PropTypes.array,
}

export default SectionPublishedResearch
